<script>
import './PlaneCheckbox.styl'

export default {
  name: 'PlaneCheckbox',
  props: {
    label: String,
    isChecked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked: {
      get () {
        return this.isChecked;
      },
      set (value) {
        this.$emit('update:isChecked', value);
      },
    },
  },
}
</script>

<template>
  <label :class="{'default-checkbox': true, 'disabled-checkbox': isDisabled}">
    <input
      type="checkbox"
      v-model="checked"
      :disabled="isDisabled"
    />
    <i class="checkbox-icon"></i>
    <span v-if="label" class="checkbox-span">{{label}}</span>
  </label>
</template>
