<template>
  <modal name="FlyerShortInfoPopup" transition="pop-out" @closed="hide"
  >
    <div class="requestsDashboardDetail">
      <div
        class="requestsDashboardDetail__inner"
      >
        <div class="requestsDashboardDetail__title">Flyer info</div>
        <div class="requestsDashboardDetail__info" v-if="flyerInfo">
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Id</div>
            <div class="requestsDashboardDetail__info-item-value">{{flyerInfo.flyerId}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Name</div>
            <div class="requestsDashboardDetail__info-item-value">{{flyerInfo.flyerName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Email</div>
            <div class="requestsDashboardDetail__info-item-value">{{flyerInfo.flyerEmail}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">mobile</div>
            <div class="requestsDashboardDetail__info-item-value">{{flyerInfo.flyerMobile}}</div>
          </div>
        </div>
      </div>

      <button class="fancybox-button fancybox-close-small" @click="hide"></button>
    </div>
  </modal>
</template>

<script>
  import './RequestsDetailPopup.styl'

  export default {
    name: 'FlyerShortInfoPopup',
    props: ['flyerInfo'],
    methods: {
      hide () {
        this.$modal.hide('FlyerShortInfoPopup')
        this.$emit('clearInfo')
      },
    },
  }
</script>
