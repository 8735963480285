<script>

import './UsersSubscriptions.styl'
import Spinner from '@/components/common/Spinner/Spinner.vue';
import UsersSubscriptionsTable from '@/components/dashboard/UsersSubscriptionsTable/UsersSubscriptionsTable.vue';
import { reportsAPI } from '@/api/reports';

export default {
  name: 'users-subscriptions',
  components: {
    UsersSubscriptionsTable,
    Spinner,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    subsList () {
      return this.$store.state.usersSubscriptions.paginatedSubscriptions
    },
    subsPageCount () {
      return this.$store.state.usersSubscriptions.paginatedSubscriptionsPageCount
    },
    subsCount () {
      return this.$store.state.usersSubscriptions.paginatedSubscriptionsCount
    },
  },
  async mounted () {
  },
  methods: {
    async onSubsPageClick (data) {
      await this.$store.dispatch('fetchPaginatedUsersSubscriptions', data);
    },
    async exportData () {
      const {data} = await reportsAPI.subscriptions();

      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', data]);
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'users_subscription_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
}
</script>

<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <div class="title">Users Subscriptions</div>
      <router-link class="dashboard-export__button" to="">
        <span
          @click="exportData()"
          class="text">Export
        </span>
      </router-link>
    </div>
    <UsersSubscriptionsTable
      :pageCount="subsPageCount"
      :rowCount="subsCount"
      @pageClick="onSubsPageClick"
      :dataList="subsList"
    />
    <div v-if="loading" class="spinner-overlay">
      <Spinner/>
    </div>
  </div>
</template>
